.law {
  padding-bottom: 8rem;
  background-image: radial-gradient(
      circle at 71% 29%,
      rgba(250, 250, 250, 0.03) 0%,
      rgba(250, 250, 250, 0.03) 32%,
      transparent 32%,
      transparent 100%
    ),
    radial-gradient(
      circle at 100% 82%,
      rgba(82, 82, 82, 0.03) 0%,
      rgba(82, 82, 82, 0.03) 26%,
      transparent 26%,
      transparent 100%
    ),
    radial-gradient(
      circle at 30% 35%,
      rgba(78, 78, 78, 0.03) 0%,
      rgba(78, 78, 78, 0.03) 55%,
      transparent 55%,
      transparent 100%
    ),
    radial-gradient(
      circle at 71% 54%,
      rgba(52, 52, 52, 0.03) 0%,
      rgba(52, 52, 52, 0.03) 13%,
      transparent 13%,
      transparent 100%
    ),
    radial-gradient(
      circle at 17% 19%,
      rgba(202, 202, 202, 0.03) 0%,
      rgba(202, 202, 202, 0.03) 83%,
      transparent 83%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 30%,
      rgba(219, 219, 219, 0.03) 0%,
      rgba(219, 219, 219, 0.03) 39%,
      transparent 39%,
      transparent 100%
    ),
    radial-gradient(
      circle at 56% 19%,
      rgba(155, 155, 155, 0.03) 0%,
      rgba(155, 155, 155, 0.03) 33%,
      transparent 33%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(20, 25, 46), rgb(20, 25, 46));
  padding-top: 150px;
  color: #fff;
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  h1 {
    // text-align: center;
    color: $primary-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    padding-top: 1rem;
  }

  p {
    margin: 0.2rem 0rem;
    padding: 0rem 0rem;
  }
  h3 {
    padding-top: 0.6rem;
    color: $primary-color;
  }
  a {
    text-decoration: underline;
  }
  a.btn {
    text-decoration: none;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 2px;
    background-color: $primary-color;
    margin-right: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }
  ul {
    li,
    dd {
      padding-bottom: 0.2rem;
    }
  }
  .right-col {
    padding-bottom: 2rem;
    .content {
      background-color: $background-color-2;
      border-radius: 4px;
      padding: 1rem 12px;

      box-shadow: $main-box-shadow;
      ul {
        font-size: 0.9rem;
        color: #fff;
        .bold {
          color: #fff;
        }
      }
    }
  }
  .left-col {
    .content {
      border-radius: 4px;
      padding: 1rem 12px;
    }
  }
}
