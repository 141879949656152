.one-deck-page {
  background-color: $background-color-3;
  padding: 3rem 0rem;
  color: #fff;
  padding-bottom: 5rem;
  $inter-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.one-deck {
  color: #fff;
  padding-bottom: 5rem;
  $inter-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-1;
    color: $primary-color;
    i {
      cursor: pointer;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
    @media (max-width: 767.98px) {
      font-size: 2rem;
    }
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }
  .card-index {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-1;
    color: $primary-color;
    i {
      cursor: pointer;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
    @media (max-width: 767.98px) {
      font-size: 1rem;
    }
    @media (max-width: 575.98px) {
      font-size: 0.9rem;
    }
  }

  .back-to a {
    color: $gray-color-2;
    font-size: 1.1rem;
    font-weight: 600;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
  }
  .text-content {
    font-size: 1.1rem;
    text-align: justify;
    @media (max-width: 575.98px) {
      font-size: 1rem;
    }
  }
  .read-more {
    color: $primary-color;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: lighten($primary-color, 10%);
    }
  }
  .img-col {
    margin: 0 auto;
    max-width: 300px;

    .img-container {
      position: relative;
      width: 245px;
      margin: auto;
      height: 372px;
      .hide {
        display: none !important;
      }
    }
  }
  .type-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box {
      height: 60px;
      width: 60px;

      &.box-1 {
        background-color: #fff;
      }
      &.box-2 {
        background-color: $silver-color;
        box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 0.5rem #1f51ff, 0 0 0.1rem #1f51ff, 0 0 0.7rem #1f51ff,
          inset 0 0 0.4rem #1f51ff;
      }
      &.box-3 {
        background-color: $gold-color;

        box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 1rem #ffd700, 0 0 0.4rem #ffd700, 0 0 1.4rem #ffd700,
          inset 0 0 0.7rem #ffd700;
      }
    }
    .one-type {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      .text {
        transition: $transition;
        padding-top: 0.3rem;
        border-bottom: $background-color-3 2px solid;
      }
      &.active {
        .text {
          border-bottom: $primary-color 2px solid;
        }
      }
    }
  }
  .buy-now {
    font-size: 1.2rem;
  }
  .content-col {
    max-width: 600px;
    margin: 0 auto;
  }
  .sub-title {
    background-color: $background-color-1;
    // max-width: 400px;
    margin-right: auto;
    padding: 1rem 12px;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;

    border-radius: 4px;
    box-shadow: $inter-shadow;
    @media (max-width: 575.98px) {
      font-size: 1.3rem;
    }
  }

  .all-icons-row-1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .all-icons-row-2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .all-icons {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;

    .one-icon-2 {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      cursor: pointer;
      padding: 0 3rem;
      padding-bottom: 2rem;
      transition: all 0.4s ease;

      text-align: center;
      &.transparent {
        opacity: 0;
      }
    }

    img {
      height: 60px;
      width: 60px;
      object-fit: scale-down;
    }

    .number {
      font-family: $font-family-1;
      font-size: 1.6rem;
      font-weight: 600;

      padding-top: 0.4rem;
      text-align: center;
      transition: all 0.4s ease;
      &.silver {
        text-shadow: 0 0 3px #1f51ff, 0 0 7px #1f51ff;
      }
      &.gold {
        text-shadow: 0 0 3px #ffd700, 0 0 7px #ffd700;
      }
    }
    @media (max-width: 650.98px) {
      .one-icon-2 {
        padding: 0 2.5rem;
        padding-bottom: 2rem;
      }
      img {
        height: 50px;
        width: 50px;
      }
      .number {
        font-size: 1rem;
      }
    }

    @media (max-width: 475.98px) {
      .one-icon-2 {
        width: 80px;
        padding: 0 1.5rem;
        padding-bottom: 2rem;
      }
      img {
        height: 40px;
        width: 40px;
        height: 50px;
        width: 50px;
      }
    }
  }

  .mint {
    background-color: $background-color-1;
    padding: 1rem 12px;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
    max-width: 350px;
    margin-right: auto;
    border-radius: 4px;
    box-shadow: $inter-shadow;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: block;
    }
    @media (max-width: 991.98px) {
      margin: auto;
    }
    @media (max-width: 575.98px) {
      font-size: 1.2rem;
    }
  }

  .hex-button,
  .hex-button::before,
  .hex-button::after {
    transition: 0.3s all ease-out;
  }
  .hex-button {
    align-items: center;
    background: transparent;
    display: flex;
    height: 54px;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 100%;
    margin: 0 auto;
    color: #ffffff;
    z-index: 2;
    @media (max-width: 575.98px) {
      width: 280px;
      font-size: 1.1rem;
    }
  }

  .hex-button::before,
  .hex-button::after {
    background: right / 100% 100% linear-gradient(to right, $card-blue-2, $primary-color);
    content: '';
    height: 50%;
    left: 0px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .hex-button::before {
    top: 1px;
    transform: perspective(12px) rotateX(-3deg);
  }

  .hex-button::after {
    bottom: 1px;
    transform: perspective(12px) rotateX(3deg);
  }
}
