.coin-margin {
  height: 100px;
  background-color: $background-color-3;
}

.coin {
  background-color: $background-color-3;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 16rem;
  color: #fff;
  background-image: radial-gradient(
      circle at 19% 90%,
      rgba(190, 190, 190, 0.04) 0%,
      rgba(190, 190, 190, 0.04) 17%,
      transparent 17%,
      transparent 100%
    ),
    radial-gradient(
      circle at 73% 2%,
      rgba(78, 78, 78, 0.04) 0%,
      rgba(78, 78, 78, 0.04) 94%,
      transparent 94%,
      transparent 100%
    ),
    radial-gradient(
      circle at 45% 2%,
      rgba(18, 18, 18, 0.04) 0%,
      rgba(18, 18, 18, 0.04) 55%,
      transparent 55%,
      transparent 100%
    ),
    radial-gradient(
      circle at 76% 60%,
      rgba(110, 110, 110, 0.04) 0%,
      rgba(110, 110, 110, 0.04) 34%,
      transparent 34%,
      transparent 100%
    ),
    radial-gradient(
      circle at 68% 56%,
      rgba(246, 246, 246, 0.04) 0%,
      rgba(246, 246, 246, 0.04) 16%,
      transparent 16%,
      transparent 100%
    ),
    radial-gradient(
      circle at 71% 42%,
      rgba(156, 156, 156, 0.04) 0%,
      rgba(156, 156, 156, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    radial-gradient(
      circle at 46% 82%,
      rgba(247, 247, 247, 0.04) 0%,
      rgba(247, 247, 247, 0.04) 39%,
      transparent 39%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 47%,
      rgba(209, 209, 209, 0.04) 0%,
      rgba(209, 209, 209, 0.04) 45%,
      transparent 45%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(26, 32, 60), rgb(20, 25, 46));
  .text-part {
    font-size: 1.3rem;

    text-align: justify;
    a {
      font-size: 1.3rem;
      color: $primary-color;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
    .bold {
      font-weight: 600;
      // color: #000000;
    }
    .buy-now {
      color: #fff;
      max-width: 280px;
      margin: auto;
    }
    @media (max-width: 767.98px) {
      font-size: 1.1rem;
      a {
        font-size: 1.1rem;
      }
    }
  }

  .sub-title {
    font-family: $font-family-1;
    color: $primary-color;
    font-weight: 600;
    font-size: 1.6rem;
  }
  .content {
    max-width: 900px;
    margin: auto;
  }
}
