$navbar_height: 100px;
.z-nav {
  position: fixed;
  top: 0px;
  width: 100%; /* Full width */
  background-color: $background-color-2;
  z-index: 25;
  padding: 0rem 12px;
  box-shadow: $main-box-shadow;
  height: $navbar_height;
  color: #fff;
  border-bottom: 1px lighten($card-green, 40%) solid;
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: auto;
  }
  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;
    width: 33%;
    height: $navbar_height;
    cursor: pointer;

    img {
      width: 85px;
      // max-width: 200px;
      height: auto;
      max-height: 90px;
      object-fit: scale-down;
    }
    .logo-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-left: 0.75rem;
      margin-top: -8px;
      span {
        display: block;

        padding: 0 !important;
        margin: 0rem !important;
        line-height: 1.4rem;
        font-size: 0.9rem;
        font-family: $font-family-1;
      }
    }
  }
  .z-nav-pages {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 33%;
    height: $navbar_height;
    .z-nav-page {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem 0.9rem;
      height: $navbar_height;
      cursor: pointer;
      transition: $transition;
      pointer-events: none;

      a {
        font-size: 1.1rem;
        color: #fff;
        z-index: 30;
        transition: $transition;
        pointer-events: all;
        font-weight: 600;
        &.normal-hover {
          &:hover {
            color: $card-green;
          }
        }
      }
    }
  }
  .z-burger {
    height: $navbar_height;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
    div {
      width: 25px;
      height: 3px;
      background-color: #ffffff;
      margin: 2.5px;
      transition: all 0.3s ease;
    }
  }
  .dropdown-element {
    position: relative;
    pointer-events: all;

    .dropdown-btn {
      background: none;
      text-decoration: none;
      border: none;
      font-size: 1.1rem;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      i {
        margin-left: 0.1rem;
        pointer-events: none;
      }
      &:hover {
        color: $card-green;
      }
    }
    ul {
      margin-top: 35px;
      position: absolute;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      width: 290px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid $border-color;
      left: -25px;
      list-style: none;
      transition: all 0.4s ease;
      z-index: -100;
      transform: translateY(-10px);
      pointer-events: none;
      opacity: 0;
    }

    li {
      text-align: start;
      width: 100%;
      padding: 0.75rem 0rem;
      padding-left: 0.6rem;
      transition: $transition;
      border-left: 4px solid #ffffff;
      cursor: pointer;
      pointer-events: none;
      a {
        color: #333 !important;
        font-size: 1rem !important;
        pointer-events: none !important;
      }
      &:hover {
        background-color: lighten(#333, 75%);
        border-left: 4px solid $card-green;
      }
    }

    &.active {
      ul {
        z-index: 100;
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }
      li {
        pointer-events: all !important;
        a {
          pointer-events: all !important;
        }
      }
    }
  }
  .social-media-container {
    width: 33%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-part {
    font-size: 1.2rem;
    margin-left: auto;
    @media (max-width: 1150.98px) {
      display: none;
    }
  }
  .button-part {
    height: 50px;

    max-width: 150px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    a {
      height: 60px;
      width: 60px;

      border-bottom: 2px solid $background-color-2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 30px;
      transition: all 0.25 ease-out;
      &:hover {
        border-bottom: 2px solid $card-green;
      }
    }
    @media (max-width: 1350.98px) {
      max-width: 120px;
    }
    @media (max-width: 1200.98px) {
      display: none;
    }
  }

  .instagram-img {
    height: 24px;
    width: auto;
  }

  .reddit-img {
    height: 24px;
    width: auto;
  }

  .twitter-img {
    height: 22px;
    width: auto;
  }

  .instagram-img {
    height: 23px;
    width: auto;
  }

  .discord-img {
    height: 24px;
    width: auto;
  }
  .telegram-img {
    height: 24px;
    width: auto;
    @media (max-width: 1350.98px) {
      display: none;
    }
  }

  .small-screen {
    display: none !important;
    i {
      pointer-events: none;
    }
    ul {
      padding-top: 0.2rem;
      display: none;
      li {
        padding-bottom: 0.2rem;
        text-align: center;
      }
    }
    &.active {
      ul {
        display: block;
      }
    }
  }
}

@media (max-width: 1150.98px) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }

  .z-nav {
    .z-logo-container {
      width: 50%;

      justify-content: flex-start;
    }
    .z-nav-pages {
      background-color: $background-color-2;
      display: flex;
      position: absolute;
      right: 0px;
      min-width: 300px;
      max-width: 800px;
      top: 100px;
      flex-direction: column;
      height: unset;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
        0 8px 8px rgba(0, 0, 0, 0.15);
    }
    .z-nav-page {
      height: unset !important;
      opacity: 0;
      padding: 0.75rem 0rem !important;
      padding-right: 0rem !important;
      width: 100%;
    }
    li {
      padding: 0.5rem 0rem !important;
    }
    .z-burger {
      display: flex;
      div {
        background-color: $card-green !important;
      }
    }
    .small-screen {
      display: flex !important;
      flex-direction: column;
    }
    .big-screen {
      display: none !important;
    }
  }
}

@media (max-width: 500.98px) {
  .z-nav {
    .z-logo-container {
      width: 80%;
      img {
        max-width: 150px;
        height: auto;
        object-fit: cover;
      }
      .logo-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-left: 0.75rem;
        margin-top: -8px;
        span {
          display: block;

          padding: 0 !important;
          margin: 0rem !important;
          line-height: 1.4rem;
          font-size: 0.8rem;
          font-family: $font-family-1;
        }
      }
    }
  }
}
@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.z-toggle {
  div {
    background-color: $third-color !important;
  }
  .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
