.rarity {
  padding-bottom: 8rem;

  .row {
    max-width: 1100px;
    margin: auto;
  }
  .one-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    flex-direction: column;
  }
  .one-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    text-align: center;
    flex-direction: column;
    border-radius: 4px;
    &.silver-border {
      background-color: $silver-color;
      box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 1rem #1f51ff,
        0 0 0.4rem #1f51ff, 0 0 1.4rem #1f51ff, inset 0 0 0.7rem #1f51ff;
      font-weight: 600;
      color: #333;
    }

    &.gold-border {
      background-color: $gold-color;
      box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #ffd700,
        0 0 0.8rem #ffd700, 0 0 2.8rem #ffd700, inset 0 0 1.3rem #ffd700;
      font-weight: 600;
      span {
        text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
          0 0 42px #ffd700, 0 0 82px #ffd700, 0 0 92px #ffd700,
          0 0 102px #ffd700, 0 0 151px #ffd700;
        color: #fff;
        font-size: 1.8rem;
      }
    }

    span {
      font-size: 1.5rem;
    }

    width: 245px;
    height: 100px;
  }

  .one-flipcard-2-rarity {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
