.social-media {
  position: relative;
  // background: url("../../img/home/background_2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 750px;
  padding: 6rem 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // background-attachment: fixed;
  //   opacity: 0.8;
  @media (max-width: 575.98px) {
    // background: #ffffff;
    height: unset;
    padding: 3rem 0rem;
  }
  .has-overlay::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.5);
  }

  .content-container {
    position: relative;
    color: #fff;
    max-width: 950px;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 767.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }

  .title {
    font-size: 3.2rem;
    font-family: $font-family-1;
    color: $card-green;
    font-weight: 600;
  }

  .sub-title {
    font-size: 2rem;

    font-weight: 600;
  }

  @media (max-width: 575.98px) {
    .title {
      font-size: 2.5rem;
    }

    .sub-title {
      font-size: 1.5rem;
    }
  }

  .one-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: solid 2px #fff;
    // padding: 2rem 1.5rem 3rem 1.5rem;
    width: 160px;
    height: 240px;
    border-radius: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.6s ease-in-out all;
    margin-bottom: 2rem;
    &:hover {
      box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 1rem $card-green,
        0 0 0.4rem $card-green, 0 0 1.4rem $card-green,
        inset 0 0 1.3rem $card-green;
      img {
        transform: scale(1.05);
      }
    }
    p {
      padding-top: 1rem;
      font-size: 1.3rem;
      color: #fff;
    }
  }
  img {
    height: 80px;
    width: 80px;
    object-fit: scale-down;
    transition: 0.6s ease-in-out all;
  }
  .partner-part {
    .one-col {
      max-width: 900px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 6rem;

      svg,
      img {
        cursor: pointer;
        transition: $transition;
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
  .wax {
    width: 180px;
    height: auto;
  }
  .atomic {
    width: 180px;
    height: auto;
  }
}
