@import "./back_2_top.scss";
@import "./_navbar.scss";
@import "./one-accordion";
.tippy-content {
  background: #000000;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 6px 4px;
  max-width: 300px;
  z-index: 2000;
  text-align: center;
}
