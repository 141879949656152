$timeline-color: $card-green;
$timeline-background: #2b2e47;
$timeline-inactive: $gray-color;
.timeline-section {
  padding-bottom: 7rem;
}
.timeline {
  position: relative;
  background-color: $timeline-background;

  .main-timeline {
    position: relative;
  }

  .main-timeline:before,
  .main-timeline:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
  }
  .main-timeline:before {
    content: "";
    width: 6px;
    height: 100% !important;
    background: $timeline-color;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
  }
  .main-timeline .timeline {
    width: 50%;
    float: left;
    position: relative;
  }
  .main-timeline .timeline:before,
  .main-timeline .timeline:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
  }

  .main-timeline .timeline:last-child:nth-child(even):before {
    right: auto;
    left: -12px;
    bottom: -2px;
  }
  .main-timeline .timeline-content {
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease 0s;
  }
  .main-timeline .timeline-content:before {
    content: "";
    width: 100%;
    height: 5px;
    background: $timeline-color;

    position: absolute;
    top: 88px;
    left: 0;
  }
  .main-timeline .circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid $timeline-color;
    float: left;
    margin-right: 25px;
    position: relative;
    &.active {
      box-shadow: 0 0 0.1rem $card-green, 0 0 0.1rem $card-green,
        0 0 1rem $card-green, 0 0 0.4rem $card-green, 0 0 1.4rem $card-green,
        inset 0 0 1.3rem $card-green;
    }
  }
  .main-timeline .circle:before {
    content: "";
    width: 26px;
    height: 30px;
    background: $timeline-color;
    margin: auto;
    position: absolute;
    top: 0;
    right: -33px;
    bottom: 0;
    box-shadow: inset 7px 0 9px -7px #444;
  }
  .main-timeline .circle span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 268px;
    font-size: 80px;
    color: $timeline-color;
  }
  .main-timeline .circle span:before,
  .main-timeline .circle span:after {
    content: "";
    width: 28px;
    height: 50px;
    background: $timeline-background;
    // background: blue;
    opacity: 1;
    border-radius: 0 0 0 21px;
    margin: auto;
    position: absolute;
    top: -54px;
    right: -33px;
    bottom: 0;
  }
  .main-timeline .circle span:after {
    border-radius: 21px 0 0 0;
    top: 0;
    bottom: -56px;
  }
  .main-timeline .circle .img {
    vertical-align: initial;
    border-radius: 50%;
    width: 165px;
    height: 165px;
    cursor: pointer;
    transition: all 0.8s ease;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    &.active {
      -webkit-filter: unset;
      filter: unset;
    }
    &:hover {
      transform: rotate(90deg);
    }
  }
  .main-timeline .content {
    display: table;
    padding-right: 20px;
    position: relative;
  }
  .main-timeline .year {
    display: block;
    padding: 10px;
    margin: 0px 0 50px 0;
    background: $timeline-color;
    border-radius: 6px;
    font-size: 1.2rem;
    color: #fff;
    &.active {
      box-shadow: 0 0 0.2rem $card-green, 0 0 0.2rem $card-green,
        0 0 2rem $card-green, 0 0 0.8rem $card-green, 0 0 2.8rem $card-green,
        inset 0 0 1.3rem $card-green;
    }
  }
  .main-timeline .title {
    font-size: 1.3rem;
    font-weight: bold;
    color: $timeline-color;
    font-family: $font-family-1;
    margin-top: 0;
    padding-bottom: 0.5rem;
  }
  .main-timeline .description {
    font-size: 1.1rem;
    color: #fff;
    text-align: justify;

    a {
      font-size: 1.1rem;
    }
  }
  .main-timeline .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $timeline-color;
    border: 4px solid $timeline-color;
    position: absolute;
    top: 78px;
    right: -14px;
  }
  .main-timeline .icon:before {
    content: "";
    width: 15px;
    height: 25px;
    background: $timeline-color;
    margin: auto;
    position: absolute;
    top: -1px;
    left: -15px;
    bottom: 0;
  }
  .main-timeline .icon span:before,
  .main-timeline .icon span:after {
    content: "";
    width: 21px;
    height: 25px;
    background: $timeline-background;
    border-radius: 0 0 21px 0;
    margin: auto;
    position: absolute;
    top: -30px;
    left: -15px;
    bottom: 0;
  }
  .main-timeline .icon span:after {
    border-radius: 0 21px 0 0;
    top: 0;
    left: -15px;
    bottom: -30px;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content,
  .main-timeline .timeline:nth-child(2n) .circle {
    float: right;
  }
  .main-timeline .timeline:nth-child(2n) .circle {
    margin: 0 0 0 25px;
  }
  .main-timeline .timeline:nth-child(2n) .circle:before {
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
  }
  .main-timeline .timeline:nth-child(2n) .circle span:before,
  .main-timeline .timeline:nth-child(2n) .circle span:after {
    right: auto;
    left: -33px;
    border-radius: 0 0 21px 0;
  }
  .main-timeline .timeline:nth-child(2n) .circle span:after {
    border-radius: 0 21px 0 0;
  }
  .main-timeline .timeline:nth-child(2n) .content {
    padding: 0 0 0 40px;
    margin-left: 2px;
  }
  .main-timeline .timeline:nth-child(2n) .icon {
    right: auto;
    left: -14px;
  }
  .main-timeline .timeline:nth-child(2n) .icon:before,
  .main-timeline .timeline:nth-child(2n) .icon span:before,
  .main-timeline .timeline:nth-child(2n) .icon span:after {
    left: auto;
    right: -15px;
  }
  .main-timeline .timeline:nth-child(2n) .icon span:before {
    border-radius: 0 0 0 21px;
  }
  .main-timeline .timeline:nth-child(2n) .icon span:after {
    border-radius: 21px 0 0 0;
  }
  .main-timeline .timeline:nth-child(2) {
    margin-top: 180px;
  }
  .main-timeline .timeline:nth-child(odd) {
    margin: -175px 0 0 0;
  }
  .main-timeline .timeline:nth-child(even) {
    margin-bottom: 180px;
  }
  .main-timeline .timeline:first-child,
  .main-timeline .timeline:last-child:nth-child(even) {
    margin: 0;
  }

  .content.inactive {
    .year {
      background-color: $timeline-inactive;
      color: #333;
    }
    .title,
    .description {
      color: $timeline-inactive;
    }
  }

  .year {
    font-family: $font-family-1;
  }

  a {
    color: $primary-color;
    transition: $transition;
  }

  .blur-text {
    color: transparent !important;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
  }
  .accordion-btn {
    color: $primary-color;
    transition: $transition;
    &:hover {
      color: darken($primary-color, 10%);
    }
  }
  @media only screen and (max-width: 990px) {
    .main-timeline:before {
      left: 100%;
    }
    .main-timeline .timeline {
      width: 100%;
      float: none;
      margin-bottom: 20px !important;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before {
      left: auto !important;
      right: -13px !important;
    }
    .main-timeline .timeline:nth-child(2n) .circle {
      float: left;
      margin: 0 25px 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle:before {
      right: -33px;
      left: auto;
      box-shadow: 7px 0 9px -7px #444 inset;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:before,
    .main-timeline .timeline:nth-child(2n) .circle span:after {
      right: -33px;
      left: auto;
      border-radius: 0 0 0 21px;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:after {
      border-radius: 21px 0 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .content {
      padding: 0 40px 0 0;
      margin-left: 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon {
      right: -14px;
      left: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon:before,
    .main-timeline .timeline:nth-child(2n) .icon span:before,
    .main-timeline .timeline:nth-child(2n) .icon span:after {
      left: -15px;
      right: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:before {
      border-radius: 0 0 21px 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:after {
      border-radius: 0 21px 0 0;
    }
    .main-timeline .timeline:nth-child(2),
    .main-timeline .timeline:nth-child(odd),
    .main-timeline .timeline:nth-child(even) {
      margin: 0;
    }
    .description {
      font-size: 1rem;
      a {
        font-size: 1rem;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .main-timeline:before {
      left: 0;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before {
      left: -12px !important;
      right: auto !important;
    }
    .main-timeline .circle,
    .main-timeline .timeline:nth-child(2n) .circle {
      width: 130px;
      height: 130px;
      float: none;
      margin: 0 auto;
    }
    .main-timeline .timeline-content:before {
      width: 99.5%;
      top: 68px;
      left: 0.5%;
    }
    .main-timeline .circle span {
      line-height: 115px;
      font-size: 60px;
    }
    .main-timeline .circle:before,
    .main-timeline .circle span:before,
    .main-timeline .circle span:after,
    .main-timeline .icon {
      display: none;
    }
    .main-timeline .content,
    .main-timeline .timeline:nth-child(2n) .content {
      padding: 0 10px;
    }
    .main-timeline .year {
      margin-bottom: 15px;
    }
    .main-timeline .description {
      text-align: center;
    }

    .img {
      width: 110px;
      height: 110px;
      transform: translate(-18px, -20px);
      //   display: none;
    }
    .year {
      margin-top: 3rem !important;
    }
  }
}
