.video {
  display: flex;
  min-height: 100vh;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 60%,
    rgba(43, 46, 71, 1) 100%
  );
  border-top: 2px solid $card-green;
  border-bottom: 2px solid $card-green;
  padding: 2rem 0rem;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;

  .sky {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -80px;
    overflow: hidden;
    pointer-events: none;
  }
  .star {
    --x: 0%;
    --y: 0%;
    --opacity: 1;
    --size: 1;
    --delay: 0s;
    --color: #fff;
    position: relative;
    top: var(--y);
    left: var(--x);
    width: 2px;
    height: 2px;
    transform: scale(var(--size));
    animation: blink 1s ease-in-out infinite alternate;
    animation-delay: var(--delay);
    opacity: var(--opacity);
    border-radius: 50%;
    background-color: var(--color);
    box-shadow: 0 0 8px var($card-green);
    // box-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
  }

  @keyframes blink {
    from {
      opacity: 1;
    }
  }

  @media (max-width: 575.98px) {
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(43, 46, 71, 1) 100%
    );
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    height: auto;
    min-height: 750px;
    z-index: 1;
    margin: auto;
    padding: 6rem 0rem;
    @media (max-width: 991.98px) {
      padding: 1rem 0rem;
      min-height: 600px;
    }
    @media (max-width: 575.98px) {
      padding: 2rem 0rem;
      min-height: 350px;
    }
    .screen {
      width: 100%;
      background-color: #000 !important;
      cursor: pointer;
    }

    @media (max-width: 800px) {
      .screen,
      .controls {
        width: 90%;
      }
    }

    @media (max-width: 991.98px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .video-content {
        width: 100%;
        max-width: 600px;
        margin: auto;
      }
    }
  }
}
