/* General styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #ffffff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.preload * {
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}
a {
  text-decoration: none;
  color: #333;
  transition: $transition;
  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}
ul {
  list-style: none;
}

.shy {
  hyphens: auto;
}
.mobile-overflow-wrapper {
  overflow-x: hidden;
}
.dark-background {
  background-color: $dark-color;
}

.text-justify {
  text-align: justify;
}
.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

.size-24 {
  font-size: 24px;
}

.size-32 {
  font-size: 32px;
}

.size-40 {
  font-size: 40px;
}

.size-48 {
  font-size: 48px;
}

.size-64 {
  font-size: 64px;
}

.size-80 {
  font-size: 80px;
}

.size-96 {
  font-size: 96px;
}

@media (max-width: 767.98px) {
  .size-16-md {
    font-size: 16px;
  }

  .size-18-md {
    font-size: 18px;
  }

  .size-24-md {
    font-size: 24px;
  }

  .size-32-md {
    font-size: 32px;
  }
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.black-bold {
  font-weight: 900;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-family-1 {
  font-family: $font-family-1;
}

.primary-color {
  color: $primary-color;
}

.second-color {
  color: $secondary-color;
}
.third-color {
  color: $third-color;
}

.fourth-color {
  color: $fourth-color;
}

.white-color {
  color: #fff;
}

.red-color {
  color: $red-color;
}
