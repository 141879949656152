.card-pakete-whole {
  background-image: radial-gradient(
      circle at 33% 41%,
      rgba(250, 250, 250, 0.03) 0%,
      rgba(250, 250, 250, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 100%
    ),
    radial-gradient(
      circle at 76% 49%,
      rgba(28, 28, 28, 0.03) 0%,
      rgba(28, 28, 28, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 100%
    ),
    radial-gradient(
      circle at 41% 99%,
      rgba(247, 247, 247, 0.03) 0%,
      rgba(247, 247, 247, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 100%
    ),
    radial-gradient(
      circle at 66% 27%,
      rgba(17, 17, 17, 0.03) 0%,
      rgba(17, 17, 17, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 100%
    ),
    linear-gradient(191deg, rgb(20, 25, 46), rgb(26, 32, 60));
  padding-bottom: 5rem;
}
.card-pakete {
  .one-paket {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 3rem;
    transition: $transition;
  }
  .photo-stack {
    position: relative;
    margin: auto;
    width: 200px;
    height: 350px;
    cursor: pointer;
  }

  h3 {
    font-size: 2.2rem;
    color: $card-green;
    padding-bottom: 2rem;
  }
  .desc {
    color: #fff;
    padding-top: 0rem;
    font-size: 1.4rem;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 0.5rem;
      font-weight: 600;
      i {
        color: $card-green;
        margin-right: 0.75rem;
      }
    }
  }

  .price {
    text-align: center;
    font-size: 2rem;
    padding-top: 1rem;
    i {
      color: $card-green;
      margin-right: 0.75rem;
    }
  }

  .buy-now {
    font-size: 1.4rem;
    font-weight: 600;
  }
  // img {
  //   position: absolute;
  //   top: 0;
  //   left: 0;

  //   box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.5);
  //   transition: all 0.3s ease-out;
  //   width: 200px;
  //   height: 330px;
  //   object-fit: cover;
  //   &.img-0 {
  //     object-fit: cover;
  //   }
  // }
  .img-1 {
    z-index: 20;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    &.transparent {
      opacity: 0;
    }
  }
  .img-0 {
    z-index: 18;
  }

  .active {
    transition: $transition;

    img.img-2 {
      transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    img.img-3 {
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    img.img-4 {
      transform: translate3d(2%, -2%, 0) rotate3d(0, 0, 1, 2deg);
    }

    img.img-6 {
      transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 6deg);
    }
    img.img-7 {
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -10deg);
    }
    img.imh-8 {
      transform: translate3d(2%, -2%, 0) rotate3d(0, 0, 1, 8deg);
    }

    //   hover

    .photo-stack:hover img.img-1 {
      transform: scale(1.02);
    }
    .photo-stack:hover img.img-2 {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    .photo-stack:hover img.img-3 {
      transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    .photo-stack:hover img.img-4 {
      transform: translate3d(2%, -5%, 0) rotate3d(0, 0, 1, 2deg);
    }
    .photo-stack:hover img.img-5 {
      transform: translate3d(-5%, -2%, 0) rotate3d(0, 0, 1, 2deg);
    }

    .photo-stack:hover img.img-6 {
      transform: translate3d(8%, 0, 0) rotate3d(0, 0, 1, 6deg);
    }
    .photo-stack:hover img.img-7 {
      transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, -10deg);
    }
    .photo-stack:hover img.imh-8 {
      transform: translate3d(8%, -2%, 0) rotate3d(0, 0, 1, 8deg);
    }
  }
}
