// problem blur in safari....

@mixin size1 {
  width: 245px;
  height: 372px;
}
@mixin size2 {
  @include size1();
}
@mixin sizeImg {
  width: 243px;
  height: 370px;
  transform: translate(-1px, -1px);
  //   object-fit: scale-down;
}

@mixin border-animation {
  animation: steam 24s linear infinite;
}

@mixin blur {
  filter: blur(14px);
}
@mixin blur2 {
  filter: blur(28px);
}
.one-flipcard-2 {
  position: relative;
  @include size1();

  margin: 2rem auto;
}

.flipcard-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &.flip-action {
    .flip {
      transform: rotateY(180deg);
    }
  }
  .thecard {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: all 1.8s ease;

    &.backside {
      transform: rotateY(180deg);
    }
  }

  .thefront {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    background: linear-gradient(0deg, #000, #272727);
    z-index: 1;
    transition: $transition;
    @include size2();
    img {
      @include sizeImg();
    }
    &.silver-border {
      transition: $transition;
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(
          45deg,
          #d3d3d3,
          #c0c0c0,
          #a9a9a9,
          #808080,
          #1f51ff,
          #d3d3d3,
          #c0c0c0,
          #a9a9a9,
          #808080,
          #1f51ff
        );
        background-size: 400%;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: -1;
        @include border-animation();
      }

      &:after {
        @include blur();
      }
    }
    &.gold-border {
      transition: $transition;
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(
          45deg,
          #fafad2,
          #eee8aa,
          #f0e68c,
          #daa520,
          #ffd700,
          #fafad2,
          #eee8aa,
          #f0e68c,
          #daa520,
          #ffd700
        );
        background-size: 400%;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: -1;
        @include border-animation();
      }

      &:after {
        @include blur2();
      }
    }
  }

  .theback {
    padding: 0px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    @include size2();
    img {
      @include sizeImg();
    }
    .more-btn {
      position: absolute;
      z-index: 2;
      width: 150px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      background-color: $card-green;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken($card-green, 10%);
      }
    }
  }
  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}
