.game-unter {
  padding: 0rem 0rem;

  .content {
    padding: 0rem 12px;
    max-width: 600px;
    margin: auto;
    color: #f2f2f2;
  }

  .top-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 700px;
    margin: auto;
    @media (max-width: 991.98px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .buttom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .intern-btn {
    font-family: $font-family-1;
    width: 260px;
  }
  .font-family-2 {
    font-family: $font-family-2;
    font-size: 2.75rem;
  }
  .content-text {
    padding: 0rem 12px;
    max-width: 800px;
    margin: auto;
    color: #f2f2f2;
    font-size: 1.3rem;
    text-align: justify;
    a {
      font-size: 1.3rem;
      transition: $transition;
    }
    @media (max-width: 767.98px) {
      font-size: 1.1rem;
      a {
        font-size: 1.1rem;
      }
    }
  }

  .img-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2rem 0rem;
    justify-content: center;
  }

  h1 {
    font-family: $font-family-1;
    font-size: 2rem;
    text-align: center;
    color: $card-green;
  }

  .sub-title {
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
  }
  .intro {
    font-weight: 600;
    font-size: 1.75rem;
    color: $card-green;
    font-family: $font-family-1;
    text-transform: uppercase;
  }

  .attribures-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .game-img {
    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    width: 100%;
    max-width: 600px;
    height: auto;
    text-align: center;
    object-fit: cover;
  }
  .text-span {
    display: block;
    margin-left: 0.75rem;
    transform: translateY(-3px);
  }
  .img-span {
    display: block;
  }

  .attribures-all-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding-bottom: 2rem;
    font-size: 1.3rem;
    padding-left: 2rem;
  }

  .rules {
    max-width: 650px;
    margin: auto;
  }
  @media (max-width: 575.98px) {
    .attribures-all {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .attribures-all-1 {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    .rules {
      max-width: 300px;
      margin: auto;
    }
    .attribures-all-2 {
      font-size: 1.1rem;
    }
  }
}
