//  Showcase

$showcase-height: 975px;
$showcase-height-0: 700px;
$showcase-height-1: 500px;
$showcase-height-2: 300px;
$showcase-height-3: 500px;
$showcase-height-4: 300px;
$showcase-unterseite: 400px;
.showcase {
  margin-top: 100px;
  width: 100%;
  height: $showcase-height;
  position: relative;
  text-align: center;
  background-color: $background-color-2;
  .overlay {
    width: 100%;
    height: $showcase-height;
    background-color: rgba(#333, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .back-img {
    width: 100%;
    height: $showcase-height;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 2px $card-green solid;
  }

  &.showcase_unterseite {
    height: $showcase-unterseite !important;
    .overlay {
      height: $showcase-unterseite !important;
    }
    .back-img {
      height: $showcase-unterseite !important;
    }
    h1 {
      margin-top: 120px !important;
    }
  }
  h1 {
    padding: 0 12px;
    margin-top: 225px;
    color: #ffffff;

    text-align: center;
    font-family: $font-family-1;
  }

  .h1-fett {
    font-size: 4rem;
    font-weight: 600;
  }
  .h1-klein {
    // text-shadow: unset;
    margin-top: 1rem;
    display: block;
    font-size: 2rem;
  }

  .banner-img {
    // position: relative;
    margin: auto;
    margin-top: 150px;
    height: 250px;
    width: 100%;
    max-width: 1000px;
    object-fit: scale-down;
  }

  .text-container {
    padding: 2rem 0rem;
  }

  @media (max-width: 1999.98px) {
    height: $showcase-height-0;
    .overlay {
      height: $showcase-height-0;
    }
    .back-img {
      height: $showcase-height-0;
    }
    h1 {
      margin-top: 150px;
    }
    .h1-fett {
      font-size: 3rem;
    }
    .text-container {
      padding: 1rem 0rem;
    }
  }

  @media (max-width: 1499.98px) {
    height: $showcase-height-1;
    .overlay {
      height: $showcase-height-1;
    }
    .back-img {
      height: $showcase-height-1;
    }
    h1 {
      margin-top: 150px;
    }
    .h1-fett {
      font-size: 3rem;
    }
    .text-container {
      padding: 1rem 0rem;
    }
  }

  @media (max-width: 950.98px) {
    height: $showcase-height-2;
    .overlay {
      height: $showcase-height-2;
    }
    .back-img {
      height: $showcase-height-2;
    }
    h1 {
      margin-top: 150px;
    }
    .h1-fett {
      font-size: 3rem;
    }
    .text-container {
      padding: 1rem 0rem;
    }
  }
  @media (max-width: 767.98px) {
    height: $showcase-height-3;
    .overlay {
      height: $showcase-height-3;
    }
    .back-img {
      height: $showcase-height-3;
    }
    h1 {
      margin-top: 150px;
    }
    .h1-fett {
      font-size: 2.2rem;
    }
    .h1-klein {
      font-size: 1.2rem;
    }
    .text-container {
      padding: 1rem 0rem;
    }
  }

  @media (max-width: 575.98px) {
    height: $showcase-height-4;
    .overlay {
      height: $showcase-height-4;
    }
    .back-img {
      height: $showcase-height-4;
    }
    h1 {
      margin-top: 150px;
    }
    .h1-fett {
      font-size: 2rem;
    }
    .h1-klein {
      font-size: 1.2rem;
    }
  }
}
