.video-text {
  h2 {
    color: #fff;
    font-size: 2.6rem;
    font-family: $font-family-1;
    padding-top: 2rem;
    text-align: center;
    position: relative;
    z-index: 10;
    span {
      display: block;
    }
    @media (max-width: 1199.98px) {
      font-size: 2.2rem;
    }
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 1199.98px) {
    .hex-container-small {
      display: none;
    }
  }

  .hex-container-big {
    transform: translate(-30%, -10%) rotate(-30deg);

    @media (max-width: 1199.98px) {
      display: none;
    }
  }

  .hex-button,
  .hex-button::before,
  .hex-button::after {
    transition: 0.3s all ease-out;
  }
  .hex-button {
    align-items: center;
    background: transparent;
    display: flex;
    height: 54px;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 300px;
    margin: 0 auto;
    color: #ffffff;
    z-index: 2;
    @media (max-width: 575.98px) {
      width: 280px;
      font-size: 1.1rem;
    }
  }

  .hex-button::before,
  .hex-button::after {
    background: right / 100% 100%
      linear-gradient(to right, $card-blue-2, $primary-color);
    content: "";
    height: 50%;
    left: 0px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .hex-button::before {
    top: 1px;
    transform: perspective(12px) rotateX(-3deg);
  }

  .hex-button::after {
    bottom: 1px;
    transform: perspective(12px) rotateX(3deg);
  }
  .buy-now {
    z-index: 100;
    font-size: 1.3rem;
    margin: auto;
    width: 280px;
    max-width: 100%;
  }
  .test-btn {
    z-index: 100;
  }
  .more-info {
    z-index: 100;
    cursor: pointer;
    font-weight: 600;
    text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
    a {
      color: #fff;
      font-size: 1.5rem;
      transition: $transition;
      i {
        font-size: 1.3rem;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }

  .top-part,
  .bottom-part {
    padding: 0 12px;
  }

  .magic-text {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
    transition: 1.2s ease all;
    opacity: 0;
    cursor: pointer;
    z-index: 10 !important;
    position: relative;
    text-align: center;
    @media (max-width: 1199.98px) {
      font-size: 1.5rem;
    }
    @media (max-width: 575.98px) {
      font-size: 1.2rem;
    }
    &.fade-in {
      opacity: 1;
    }
    @media (min-width: 1199.98px) {
      // &.magic-text-small {
      //   display: none !important;
      //   opacity: 0 !important;
      // }
    }
    &.extra {
      margin-top: 50%;
      margin-left: 50%;
    }
  }

  // .star {
  //   position: absolute;

  //   color: #fff;
  //   text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
  //   font-size: 1.25rem;
  //   top: 0%;
  //   opacity: 0;
  //   transition: all 1.5s ease-in-out;
  //   &.fade-in {
  //     opacity: 1;
  //   }
  // }

  .left-side-banner {
    position: absolute;
    height: 82%;
    top: 0;
    left: 0;
    width: 50%;
    padding: 6rem 12px;
  }

  .left-side {
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0;
    width: 50%;
    padding: 6rem 12px;
  }

  .right-side {
    position: absolute;
    height: 60%;
    top: 20%;
    right: 0;
    width: 50%;
    padding: 6rem 12px;
  }

  @media (max-width: 1199.98px) {
    .right-side,
    .left-side {
      display: none;
    }
  }
}

//
