.buy-now {
  box-sizing: border-box;
  appearance: none;

  border: 2px solid $card-green;
  border-radius: 8px;
  color: $card-green;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: $transition;
  &:focus {
    color: #fff;
    outline: 0;
  }
  border-color: $card-green;
  color: #fff;
  box-shadow: 0 0 40px 40px $card-green inset, 0 0 0 0 $card-green;

  &:hover {
    box-shadow: 0 0 10px 0 $card-green inset, 0 0 10px 4px $card-green;
    color: #fff;
  }
}

.play-here {
  box-sizing: border-box;
  appearance: none;

  border: 2px solid $fourth-color;
  border-radius: 8px;
  color: $fourth-color;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: $transition;
  &:focus {
    color: #fff;
    outline: 0;
  }
  border-color: $fourth-color;
  color: #fff;
  box-shadow: 0 0 40px 40px $fourth-color inset, 0 0 0 0 $fourth-color;

  &:hover {
    box-shadow: 0 0 10px 0 $fourth-color inset, 0 0 10px 4px $fourth-color;
    color: #fff;
  }
}

.nordic-title-1 {
  color: $card-green;
  font-size: 3.2rem;
  font-family: $font-family-1;
  @media (max-width: 575.98px) {
    font-size: 2.5rem;
  }
}

.nordic-special-img {
  cursor: pointer;
}

.nordic-slideBtn {
  color: #fff;
  font-size: 1.2rem;
  transition: $transition;
  // color: $primary-color;
  font-weight: 600;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
  @media (max-width: 575.98px) {
    font-size: 1rem;
  }
}
