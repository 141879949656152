.about-us {
  min-height: 100vh;
  background-image: linear-gradient(
      12deg,
      rgba(193, 193, 193, 0.05) 0%,
      rgba(193, 193, 193, 0.05) 2%,
      rgba(129, 129, 129, 0.05) 2%,
      rgba(129, 129, 129, 0.05) 27%,
      rgba(185, 185, 185, 0.05) 27%,
      rgba(185, 185, 185, 0.05) 66%,
      rgba(83, 83, 83, 0.05) 66%,
      rgba(83, 83, 83, 0.05) 100%
    ),
    linear-gradient(
      321deg,
      rgba(240, 240, 240, 0.05) 0%,
      rgba(240, 240, 240, 0.05) 13%,
      rgba(231, 231, 231, 0.05) 13%,
      rgba(231, 231, 231, 0.05) 34%,
      rgba(139, 139, 139, 0.05) 34%,
      rgba(139, 139, 139, 0.05) 71%,
      rgba(112, 112, 112, 0.05) 71%,
      rgba(112, 112, 112, 0.05) 100%
    ),
    linear-gradient(
      236deg,
      rgba(189, 189, 189, 0.05) 0%,
      rgba(189, 189, 189, 0.05) 47%,
      rgba(138, 138, 138, 0.05) 47%,
      rgba(138, 138, 138, 0.05) 58%,
      rgba(108, 108, 108, 0.05) 58%,
      rgba(108, 108, 108, 0.05) 85%,
      rgba(143, 143, 143, 0.05) 85%,
      rgba(143, 143, 143, 0.05) 100%
    ),
    linear-gradient(
      96deg,
      rgba(53, 53, 53, 0.05) 0%,
      rgba(53, 53, 53, 0.05) 53%,
      rgba(44, 44, 44, 0.05) 53%,
      rgba(44, 44, 44, 0.05) 82%,
      rgba(77, 77, 77, 0.05) 82%,
      rgba(77, 77, 77, 0.05) 98%,
      rgba(8, 8, 8, 0.05) 98%,
      rgba(8, 8, 8, 0.05) 100%
    ),
    linear-gradient(334deg, rgb(20, 25, 46), rgb(20, 25, 46));
  padding-bottom: 12rem;
  .title {
    color: $card-green;
    font-size: 3.2rem;
    font-family: $font-family-1;
    // .part-1 {
    //   display: block;
    //   font-family: $font-family-2;
    //   font-size: 4.5rem;
    //   @media (max-width: 575.98px) {
    //     font-size: 3.5rem;
    //   }

    //   @media (max-width: 400.98px) {
    //     font-size: 2.8rem;
    //   }
    // }
    .part-2 {
      font-size: 3.2rem;
      display: block;

      @media (max-width: 575.98px) {
        font-size: 2.8rem;
      }
      @media (max-width: 400.98px) {
        font-size: 2.5rem;
      }
    }
  }

  .content {
    padding-top: 5rem;
    max-width: 1100px;
    margin: auto;
    text-align: justify;
  }
  .text-content {
    max-width: 900px;
    margin: auto;
    color: #fff;
    font-size: 1.3rem;
    a {
      color: $primary-color;
      font-size: 1.3rem;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }

    @media (max-width: 767.98px) {
      font-size: 1.1rem;
      a {
        font-size: 1.1rem;
      }
    }
  }
}
