.game {
  padding: 0rem 0rem;

  .content {
    padding: 2rem 12px;
    max-width: 650px;
    margin: auto;
    color: #f2f2f2;
  }
  .title {
    font-family: $font-family-2;
    font-size: 3rem;
    text-align: center;
    color: $card-green;
  }
  .sub-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .img-col {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      max-height: 300px;
    }
  }

  .btns-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 575.98px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .intern-btn {
    font-family: $font-family-1;
    width: 260px;
  }
  .game-img {
    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    width: 100%;
    max-width: 600px;
    height: auto;
    text-align: center;
    object-fit: cover;
  }

  .game-rules {
    .intro {
      font-weight: 600;
      font-size: 1.75rem;
      color: $card-green;
      font-family: $font-family-1;
      text-transform: uppercase;
    }
    .attribures-all {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 40px;
      height: auto;
      @media (max-width: 700.98px) {
      }
      height: auto;
      width: 30px;
      @media (max-width: 575.98px) {
        height: auto;
        width: 25px;
      }
    }
    .text-span {
      display: block;
      margin-left: 0.75rem;
      transform: translateY(-3px);
    }
    .img-span {
      display: block;
    }

    .attribures-all-2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding-bottom: 2rem;
      font-size: 1.3rem;
      padding-left: 1rem;
      width: 325px;
    }

    @media (max-width: 1300px) and (min-width: 1199.98px) {
      .attribures-all-2 {
        font-size: 1.1rem;
        width: 270px;
      }
    }

    @media (max-width: 700.98px) {
      .attribures-all-2 {
        font-size: 1.1rem;
        width: 270px;
      }
    }
    @media (max-width: 575.98px) {
      max-width: 300px;
      margin: auto;
      .attribures-all {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .attribures-all-1 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      .attribures-all-2 {
        font-size: 1.1rem;
        &__last {
          justify-content: flex-start;

          width: 100%;
        }
      }
    }
  }
}
