$primary-color: #84bd32;
$secondary-color: #f8f9fa;
$third-color: darken($primary-color, 10%);
$fourth-color: #d92f61;
$header-color-2: #fffce9;
$footer-color: #445b80;
$img-background: #1b3333;

$button-color: $primary-color;
$border-color: lighten(#333, 60%);

$test-color: #e15609;

$dark-color: #0b1826;
$gray-color: #999;
$light-color: #f4f4f4;
$red-color: #dc3545;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;

$gray-color-1: #fbfbfb;
$gray-color-2: #ececec;

$primary-overlay: lighten($primary-color, 20%);

$card-blue-1: #232442;
$card-blue-2: #2a8194;
$card-green: #82b82c;
$card-lila: #845d7c;

$background-color-1: #14192e;
$background-color-2: #1a203c;
$background-color-3: #2b2e47;

$silver-color: #a9a9a9;
$gold-color: #daa520;

//Google Fonts
// @import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Cinzel+Decorative&display=swap");

@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  // src: url("/src/assets/fonts/montserrat/Montserrat-ExtraLight.otf") format("otf"),
  //   url("/src/assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Square';
  src: url('/src/assets/fonts/Square/aaa.woff2') format('woff2');
  src: url('/src/assets/fonts/Square/aaa.ttf') format('truetype'), url('/src/assets/fonts/Square/aaa.eot'),
    url('/src/assets/fonts/Square/aaa.woff2') format('woff2');
}

@font-face {
  font-family: 'MorrisRoman-Black-2';
  src: url('/src/assets/fonts/Black_2/aaa.woff') format('woff');
  src: url('/src/assets/fonts/Black_2/aaa.ttf') format('truetype'),
    url('/src/assets/fonts/Black_2/aaa.woff') format('woff'), url('/src/assets/fonts/Black_2/aaa.eot'),
    url('/src/assets/fonts/Black_2/aaa.woff2') format('woff2'), url('/src/assets/fonts/Black_2/aaa.svg') format('svg');
}
$transition: all 0.25s ease-out;
$font-family: 'Montserrat', Arial, Helvetica, sans-serif;
$font-family-1: 'Square', Arial, Helvetica, sans-serif;
$font-family-2: 'MorrisRoman-Black-2', Arial, Helvetica, sans-serif;

$main-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
  0 8px 8px rgba(0, 0, 0, 0.15);

// #0B153C (Dunkel blau)
// #82B82C (Helles grün)
// #187E90 (Helles blau)
// #5A8C58 (Dunkel grün)
// #87597A (Pink)
