@mixin size1Rest {
  width: 250px;
  height: 380px;
}

.rest-of-deck {
  // background-color: $background-color-1;
  padding: 0rem 0rem;
  color: #fff;
  .title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-2;
    color: $card-green;
    @media (max-width: 575.98px) {
      font-size: 2rem;
    }
  }

  .title-1 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-1;
    color: $card-green;
    @media (max-width: 575.98px) {
      font-size: 1.8rem;
    }
  }
  .title-2 {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-2;
    color: $card-green;
    @media (max-width: 575.98px) {
      font-size: 3rem;
    }
  }

  hr {
    width: 60px;
    color: $card-green;
    height: 4px;
    background-color: $card-green;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
  }
  .glider-contain {
    .glider {
      padding: 0rem 0rem;
    }

    .glider-prev,
    .glider-next {
      font-size: 1.5rem;
      background: #333333;
      color: #ffffff;
      margin-top: 1rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 50%;
      i.z-transform {
        transform: translate(-2px, 10px);
      }

      .fas {
        margin-left: 1rem;
      }
    }
    .glider-prev {
      margin-left: 1.5rem;
    }
    .glider-next {
      margin-right: 1.5rem;
    }
    &.only-one-card {
      display: none;
    }
  }

  .few-cards {
    display: none;
    align-items: center;
    justify-content: center;
    &.only-one-card {
      display: flex;
    }
  }

  @media (min-width: 768px) {
    .few-cards.only-two-card {
      display: flex;
    }
    .glider-contain.only-two-card {
      display: none;
    }
  }
  @media (min-width: 1000px) {
    .few-cards.only-three-card {
      display: flex;
    }
    .glider-contain.only-three-card {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .few-cards.only-four-card {
      display: flex;
    }
    .glider-contain.only-four-card {
      display: none;
    }
  }

  .one-icon {
    padding: 0px 12px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
}
