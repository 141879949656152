.decks {
  background-color: $background-color-3;
  min-height: 100vh;

  padding-bottom: 10rem;
  color: #fff;

  .one-deck {
    padding-bottom: 5rem;
  }
  h2 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-1;
    color: $card-green;
    @media (max-width: 575.98px) {
      font-size: 2rem;
    }
  }
  hr {
    width: 60px;
    color: $card-green;
    height: 4px;
    background-color: $card-green;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
  }
  .text-content {
    max-width: 750px;
    margin: auto;
    font-size: 1.1rem;
    @media (max-width: 575.98px) {
      font-size: 1rem;
    }
  }
  .hex-button-deck {
    max-width: 300px;
    margin: auto;
  }
  .read-more {
    color: $card-green;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: lighten($card-green, 10%);
    }
  }
  .flipcards-deck-lg {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .flipcards-deck-small {
    display: none;
    @media (max-width: 991.98px) {
      display: block;
    }
  }
}
