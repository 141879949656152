.mockup {
  background-image: repeating-linear-gradient(
      45deg,
      rgba(118, 118, 118, 0.05) 0px,
      rgba(118, 118, 118, 0.05) 19px,
      rgba(59, 59, 59, 0.05) 19px,
      rgba(59, 59, 59, 0.05) 67px,
      rgba(195, 195, 195, 0.05) 67px,
      rgba(195, 195, 195, 0.05) 87px,
      rgba(121, 121, 121, 0.05) 87px,
      rgba(121, 121, 121, 0.05) 133px,
      rgba(250, 250, 250, 0.05) 133px,
      rgba(250, 250, 250, 0.05) 172px,
      rgba(106, 106, 106, 0.05) 172px,
      rgba(106, 106, 106, 0.05) 197px,
      rgba(151, 151, 151, 0.05) 197px,
      rgba(151, 151, 151, 0.05) 226px,
      rgba(219, 219, 219, 0.05) 226px,
      rgba(219, 219, 219, 0.05) 260px
    ),
    repeating-linear-gradient(
      45deg,
      rgba(70, 70, 70, 0.05) 0px,
      rgba(70, 70, 70, 0.05) 40px,
      rgba(220, 220, 220, 0.05) 40px,
      rgba(220, 220, 220, 0.05) 79px,
      rgba(95, 95, 95, 0.05) 79px,
      rgba(95, 95, 95, 0.05) 103px,
      rgba(15, 15, 15, 0.05) 103px,
      rgba(15, 15, 15, 0.05) 148px,
      rgba(51, 51, 51, 0.05) 148px,
      rgba(51, 51, 51, 0.05) 186px,
      rgba(225, 225, 225, 0.05) 186px,
      rgba(225, 225, 225, 0.05) 202px,
      rgba(60, 60, 60, 0.05) 202px,
      rgba(60, 60, 60, 0.05) 239px,
      rgba(67, 67, 67, 0.05) 239px,
      rgba(67, 67, 67, 0.05) 259px
    ),
    repeating-linear-gradient(
      45deg,
      rgba(146, 146, 146, 0.05) 0px,
      rgba(146, 146, 146, 0.05) 40px,
      rgba(166, 166, 166, 0.05) 40px,
      rgba(166, 166, 166, 0.05) 54px,
      rgba(156, 156, 156, 0.05) 54px,
      rgba(156, 156, 156, 0.05) 71px,
      rgba(134, 134, 134, 0.05) 71px,
      rgba(134, 134, 134, 0.05) 95px,
      rgba(77, 77, 77, 0.05) 95px,
      rgba(77, 77, 77, 0.05) 111px,
      rgba(26, 26, 26, 0.05) 111px,
      rgba(26, 26, 26, 0.05) 153px,
      rgba(46, 46, 46, 0.05) 153px,
      rgba(46, 46, 46, 0.05) 202px,
      rgba(197, 197, 197, 0.05) 202px,
      rgba(197, 197, 197, 0.05) 216px
    ),
    linear-gradient(90deg, rgb(26, 32, 60), rgb(20, 25, 46));
  padding: 6rem 0rem;
  min-height: 100vh;
  .title-line {
    position: relative;
    padding-bottom: 1rem;
    z-index: 100;

    h3 {
      position: relative;
      overflow: hidden;
      text-align: center;
      font-size: 1.8rem;
      padding-top: 2rem;
      font-family: $font-family-2;
      color: $primary-color;
    }
    h3:before,
    h3:after {
      background-color: $primary-color;
      content: "";
      display: inline-block;
      height: 8px;
      position: relative;
      width: 50%;
    }
    h3:before {
      right: 0.5em;
      margin-left: -50%;
    }
    h3:after {
      left: 0.5em;
      margin-right: -20%;
      width: 78%;
    }
    @media (max-width: 575.98px) {
      h3:before {
        right: 0.5em;
        margin-left: -50%;
      }
      h3:after {
        left: 0.5em;
        margin-right: -80%;
        width: 78%;
      }
    }
  }
  .container {
    max-width: 800px;
    margin: auto;
  }
  .cards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .one-card {
    margin: 0rem 0.5rem;
  }
  .one {
    margin-bottom: 1rem;
  }
  img {
    width: 100%;
    height: 250px;
    object-fit: scale-down;
  }
  .one-logo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // border: $card-green solid 4px;
    img {
      width: 100%;
      height: 300px;
      transform: translateY(-100px);
      object-fit: scale-down;
    }
  }
  .score {
    background: $gray-color;
    padding: 0.5rem;
    max-width: 250px;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    margin: auto;
    transform: skew(16deg);
    text-transform: uppercase;
  }

  .name {
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    font-family: $font-family-1;
  }
  .score-you {
    background: $primary-color;
  }
}
