@import './../../scss/config';
#BoxMessage,
#MessageInHandler {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 10000;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    position: absolute;
    width: 100%;
    max-width: 900px;
    margin: auto;
    font-size: 1.2rem;
    border-radius: 4px;
    padding: 1rem;
    padding-bottom: 2rem;
    opacity: 0.98;
    color: #fff;
    max-height: 90%;
    cursor: default;
    overflow-y: auto;
    background-color: $background-color-1;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: gray;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    font-family: $font-family-1;
    @media (max-width: 575.98px) {
      font-size: 1.8rem;
    }
  }
  hr {
    width: 60px;
    color: $card-green;
    height: 4px;
    background-color: $card-green;

    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
  }

  .nordic-buy-container {
    display: flex;
    align-items: center;
    padding-top: 1.75rem;

    justify-content: space-around;
    .one-el {
      padding-bottom: 2rem;
    }
    .btn-mess {
      font-size: 1.2rem;
      width: 250px;
      padding: 0.6rem 0.75rem;
    }

    @media (max-width: 800.98px) {
      flex-direction: column;
      justify-content: center;
      .one-el {
        padding-bottom: 4rem;
      }
    }
    .img-cont {
      height: 60px;
      margin: auto;
    }
    .img-buy {
      width: 200px;
      height: auto;
      margin: auto;
      text-align: center;
    }
    .atomic-img {
      transform: translateY(-6px);
    }
  }
  @media (max-width: 575.98px) {
    padding: 1rem 12px;
    .content {
      font-size: 1rem;
    }
  }
}
